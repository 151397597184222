<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardHeader",
    data() {
        return {
            baseClasses: "px-default py-4 space-x-6",
        };
    },
    computed: {
        classes() {
            return `card-header ${this.baseClasses}`;
        },
    },
};
</script>
